import React, { useState, useEffect } from "react";
import { ref, push, set, get } from "firebase/database";
import { db, storage } from "../firebase";
import {
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import styled from "styled-components";
import EditProductForm from "./EditproductForm";

const ProductStyles = styled.div`
  width: 400px;
  margin: 50px auto;
  padding: 20px;
  border-radius: 5px;
  form {
    display: flex;
    flex-direction: column;
  }
  label {
    font-weight: bold;
    font-size: 12px;
  }
  input[type="text"],
  input[type="number"],
  textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 3px;
    box-sizing: border-box;
  }
  section {
    margin-bottom: 15px;
  }
  .add-product-button,
  .edit-button,
  .remove-image-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  .add-product-button:hover,
  .edit-button:hover,
  .remove-image-button:hover {
    background-color: #0056b3;
  }
  .add-product-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  .remove-image-button {
    background-color: #dc3545;
    padding: 8px 12px;
    font-size: 14px;
  }
  .remove-image-button:hover {
    background-color: #c82333;
  }
  .product-list {
    list-style: none;
    padding: 0;
    margin-top: 20px; /* Separate from the Existing Products heading */
  }
  .product-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding: 10px;
    background-color: #f9f9f9;
    border-radius: 5px;
  }
  .product-details {
    flex: 1;
  }
  .edit-button {
    margin-left: 10px;
    padding: 8px 12px;
    font-size: 14px;
  }
  .section-heading {
    margin-top: 30px; /* Separate the section from Add Product button */
  }
`;

export default function AddProductForm({ user }) {
  const [newProduct, setNewProduct] = useState({
    name: "",
    description: "",
    price: "",
    imageUrls: [],
  });
  const [products, setProducts] = useState([]);
  const [editingProductId, setEditingProductId] = useState(null);

  useEffect(() => {
    const fetchProducts = async () => {
      const productsRef = ref(db, "products");
      const snapshot = await get(productsRef);
      if (snapshot.exists()) {
        const data = snapshot.val();
        const productsArray = Object.entries(data).map(([id, product]) => ({
          id,
          ...product,
        }));
        setProducts(productsArray);
      }
    };

    fetchProducts();
  }, []);



  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewProduct((prevState) => ({ ...prevState, [name]: value }));
  };

  const validateForm = () => {
    return (
      newProduct.name &&
      newProduct.description &&
      newProduct.price &&
      newProduct.imageUrls.length > 0
    );
  };

  const uploadImages = async (e) => {
    const imageFiles = Array.from(e.target.files);
    const urls = await Promise.all(
      imageFiles.map(async (imageFile) => {
        const imageRef = storageRef(storage, `images/${imageFile.name}`);
        await uploadBytes(imageRef, imageFile);
        return getDownloadURL(imageRef);
      })
    );
    setNewProduct((prevState) => ({
      ...prevState,
      imageUrls: [...prevState.imageUrls, ...urls],
    }));
  };

  const handleRemoveImage = (index) => {
    setNewProduct((prevState) => ({
      ...prevState,
      imageUrls: prevState.imageUrls.filter((_, i) => i !== index),
    }));
  };

  const addProduct = async () => {
    if (!user) {
      alert("Please sign in to add a new product.");
      return;
    }

    if (!validateForm()) {
      alert("Please fill in all fields and upload at least one image.");
      return;
    }

    const productRef = push(ref(db, "products"));
    const productId = productRef.key;
    const finalProduct = { ...newProduct, productId };

    await set(productRef, finalProduct);

    setNewProduct({
      name: "",
      description: "",
      price: "",
      imageUrls: [],
    });
    document.getElementById("fileInput").value = "";
  };

  return (
    <ProductStyles>
      {user && !editingProductId && (
        <div>
          <h2>Add New Product</h2>
          <form>
            <section>
              <label>Name:</label>
              <input
                type="text"
                name="name"
                value={newProduct.name}
                onChange={handleInputChange}
              />
            </section>
            <section>
              <label>Description:</label>
              <textarea
                name="description"
                value={newProduct.description}
                onChange={handleInputChange}
                style={{ height: "100px", resize: "vertical" }}
              />
            </section>
            <section>
              <label>Price:</label>
              <input
                type="text"
                name="price"
                value={newProduct.price}
                onChange={handleInputChange}
              />
            </section>
            <section>
              <label>Images:</label>
              <input
                id="fileInput"
                type="file"
                accept="image/*"
                multiple
                onChange={uploadImages}
              />
            </section>

            {newProduct.imageUrls.map((imageUrl, index) => (
              <div key={index}>
                <img
                  src={imageUrl}
                  alt={`Product ${index}`}
                  style={{ maxWidth: "200px" }}
                />
                <button
                  type="button"
                  className="remove-image-button"
                  onClick={() => handleRemoveImage(index)}
                >
                  Remove Image
                </button>
              </div>
            ))}

            <button
              type="button"
              className="add-product-button"
              disabled={!validateForm()}
              onClick={addProduct}
            >
              Add Product
            </button>
          </form>
          <h2 className="section-heading">Existing Products</h2>
          <ul className="product-list">
            {products.map((product) => (
              <li key={product.id} className="product-item">
                <span className="product-details">
                  {product.name} - {product.price}
                </span>
                <button
                  className="edit-button"
                  onClick={() => setEditingProductId(product.id)}
                >
                  Edit
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
      {user && editingProductId && (
        <EditProductForm
          productId={editingProductId}
          onCancel={() => setEditingProductId(null)}
        />
      )}
    </ProductStyles>
  );
}
