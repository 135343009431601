import { createGlobalStyle } from "styled-components";

const Typography = createGlobalStyle`
  /* Import Fira Sans from Google Fonts */
  @import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;500;700&display=swap');

  html {
    font-family: 'Fira Sans', sans-serif;
    color: var(--gray-1);
  }

  * {
    font-family: 'Fira Sans', sans-serif;
    color: var(--gray-1);
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Fira Sans', sans-serif;
    font-weight: 700;
  }
`;

export default Typography;
