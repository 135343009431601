import styled from "styled-components";
import ProductList from "./ProductList";
import { useState, useEffect } from "react";
import { ref, onValue, off } from "firebase/database"; // Added 'off' for cleanup
import { db } from "../firebase";

const ProductCardStyles = styled.div`
  margin-top: 150px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const ProductCard = ({ deleteProduct, user }) => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const productsRef = ref(db, "products");

    // Listen for changes to the products data
    onValue(productsRef, (snapshot) => {
      const productsData = snapshot.val();
      if (productsData) {
        const productsArray = Object.keys(productsData).map((key) => ({
          id: key,
          ...productsData[key],
        }));
        setProducts(productsArray);
      } else {
        setProducts([]);
      }
    });

    // Cleanup function to unsubscribe from the database listener when the component unmounts
    return () => {
      off(productsRef); // Unsubscribe from Firebase changes
    };
  }, [deleteProduct]); // Re-run useEffect when deleteProduct changes

  return (
    <ProductCardStyles>
      {products
        .slice()
        .reverse()
        .map((product) => (
          <ProductList
            key={product.id}
            product={product}
            deleteProduct={deleteProduct}
            user={user}
          />
        ))}
    </ProductCardStyles>
  );
};

export default ProductCard;
