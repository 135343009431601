// ProductSlideshow.js
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";

const SlideStyles = styled.div`
  height: 50vh;
  width: 90vw;
  margin: 0 auto;
  border: 1px solid white;
  .slide-content {
    padding: 20px;
    margin-top: 20px;
    margin-right: 20px;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    height: 45vh;
    background-size: cover;
    background-position: center;
    color: #fff;
    justify-content: flex-end;
    align-items: flex-start;
  }
  h3 {
    font-size: 24px;
    margin-bottom: 5px;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
  }
  ul {
    font-size: 16px;
    margin-bottom: 5px;
    color: white;
  }
  li {
    list-style-type: disc;
    margin-left: 15px;
    color: white;
  }
  p {
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
  }

  @media screen and (max-width: 768px) {
    width: 85vw;
    .slide-content {
      height: 45vh;
    }
    h3 {
      font-size: 20px;
    }
    ul {
      font-size: 14px;
    }
  }

  @media screen and (max-width: 768px) {
    .quicksearch {
      width: 85vw;
    }
  }

  @media screen and (max-width: 480px) {
    .slider {
      .slide {
        display: flex !important;
        justify-content: center;
      }
    }
  }
`;

const ProductSlideshow = ({ products }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true, // Enable automatic sliding
    autoplaySpeed: 3000, // Set the interval between slides (in milliseconds)
  };

  // Adjust slidesToShow based on screen width
  if (window.innerWidth <= 480) {
    settings.slidesToShow = 1;
  }

  if (window.innerWidth <= 768 && window.innerWidth >= 480) {
    settings.slidesToShow = 2;
  }

  return (
    <SlideStyles>
      <Slider {...settings}>
        {products
          .slice()
          .reverse()
          .map((product) => (
            <div key={product.id} className="slide">
              <div
                className="slide-content"
                style={{ backgroundImage: `url(${product.imageUrls[0]})` }}
              >
                <h3>{product.name}</h3>
                <p>${product.price} plus Taxes</p>
              </div>
            </div>
          ))}
      </Slider>
    </SlideStyles>
  );
};

export default ProductSlideshow;
