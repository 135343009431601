import React, { useState } from "react";
import styled from "styled-components";
import ImageSlideshow from "./ImageSlideshow";
import Modal from "./ModalOverLay"; // Import the modal component

const PrdStyles = styled.div`
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  margin: 16px;
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  h3 {
    margin-bottom: 8px;
    font-size: 18px;
    font-weight: bold;
  }
  img {
    max-width: 100%;
    margin-bottom: 10px;
    max-height: 350px;
    object-fit: cover;
  }
  .button-list {
    display: flex;
    flex-direction: column;
  }
  button {
    background-color: var(--gray-1);
    color: black;
    font-weight: bold;
    margin: 2px;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  button:hover {
    background-color: #0056b3;
  }
  p {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
`;

const ProductList = ({ product, deleteProduct, user }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen((prev) => !prev);
  };

  return (
    <PrdStyles>
      {user && (
        <button className="delete" onClick={() => deleteProduct(product.id)}>
          X
        </button>
      )}
      {product.imageUrls && product.imageUrls.length > 0 && (
        <ImageSlideshow imageUrls={product.imageUrls} />
      )}
      <h3>{product.name}</h3>
      <p>${Number(product.price).toFixed(2)} plus Taxes</p>

      <section className="button-list">
        <button onClick={toggleModal}>View Details</button>
      </section>

      {/* Render the modal if isModalOpen is true */}
      {isModalOpen && <Modal product={product} onClose={toggleModal} />}
    </PrdStyles>
  );
};

export default ProductList;
